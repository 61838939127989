import React from 'react'

const Aln = ({ lhref, text }) => {

    let color = "darkblue";

    return <a style={{ color }} href={lhref} target="_self" >{text}</a>;

};

export { Aln };

const Span = ({ height = '50%' }) => {
    return <span style={{ "Height": height }}></span>;
}

export { Span };

const PageHeadline = ({ title }) => {
    return <div class="content-h1">
        <h1>{title}</h1>
    </div>;
}

export { PageHeadline };

const ContentHeadline = ({ title }) => {
    return <div class="content-h2">
        <h3>{title}</h3>
    </div>
}

export { ContentHeadline };

const Content = ({ c }) => {
    return <div class="content-p">{c}</div>;
}

export { Content };

const FigureCaption = ({ id, figureSource, figureCaption }) => {
    return <div class="content-img-div" >
        <figure>
            <img class="content-img" id={id} src={figureSource} alt="content-img" />
            <Span />
            <figcaption class="content-img-cap">{figureCaption}</figcaption>
        </figure>
    </div>;
}
export {FigureCaption};

const Topic = ({id, title}) =>{
    return <h4 class="content-h4" id={id}>{title}</h4>
}

export {Topic};

const SubTopic = ({id, title}) =>{
    return <h4 class="content-h4" style={{ "margin-top": "-4.2%" }} id={id}>{title}</h4>
}

export {SubTopic};


