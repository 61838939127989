

export default function Menubar({ selection }) {

    let oneStyle = { color: selection === "one" ? 'white' : "black" }
    let twoStyle = { color: selection === "two" ? 'white' : "black" }
    let threeStyle = { color: selection === "three" ? 'white' : "black" }
    let fourStyle = { color: selection === "four" ? 'white' : "black" }
    let fiveStyle = { color: selection === "five" ? 'white' : "black" }

    return (
        <div class="menu-bar" >
            <h2 class="menu-title" >THE-LIST [5]</h2>
            <ul class="nav-bar-ul">
                <MB_ITEMS id="one" lhref="/" lstyle={oneStyle} text=" 👋 : Hello !!! "/>
                <MB_ITEMS id="two" lhref="/education" lstyle={twoStyle} text=" 🎓 : My Education, "/>
                <MB_ITEMS id="three" lhref="/workandpassion" lstyle={threeStyle} text=" 💻 : Work & Passion. "/>
                <MB_ITEMS id="four" lhref="/paperworks" lstyle={fourStyle} text=" 📝 : Paper Works & "/>
                <MB_ITEMS id="five" lhref="/mydos..." lstyle={fiveStyle} text=" 🏹 : My Do's ... "/>
            </ul>
        </div>
    );
};

const MB_ITEMS = ({id, lhref, lstyle, text}) => {
    return <li class="nav-bar-items">
        <a class="hyperlink" id={id} href={lhref} style={lstyle}>
            <div class="nav-bar-items-div">
                <span class="nav-bar-span-l" >
                    <p class="nav-bar-brack" id={id}>&#123; </p>
                </span>
                <p class="nav-bar-text">{text}</p>
                <span class="nav-bar-span-r">
                    <p class="nav-bar-brack" id={id}>&#125;</p>
                </span>
            </div>
        </a>
    </li>
}