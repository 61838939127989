import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/home.js'


const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Home  section='one' />}/>
        <Route path="/education" element={<Home  section='two' />}/>
        <Route path="/workandpassion" element={<Home  section='three' />}/>
        <Route path="/paperworks" element={<Home  section='four' />}/>
        <Route path="/mydos..." element={<Home  section='five' />}/>
      </Routes>
    </Router>
  );
};

export default AppRouter;
