import { Aln, PageHeadline, ContentHeadline, Content } from "../mods/mods";

function Five() {
    return (
        <div>

            <PageHeadline title="I am Occupied by..." />
            <ContentHeadline title="a bunch of stuffs" />

            <Content c={
                <p>which are finding their way in ... & one at a time.</p>
            }/>

        </div>
    );
}

export default Five;