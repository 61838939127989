import { Aln, PageHeadline, ContentHeadline, Content, Topic, SubTopic } from "../mods/mods";

function Three() {
    return (
        <div>
            <PageHeadline title="Work and Passion" />
            <ContentHeadline title="Resume/CV" />
            <Content c={
                <p>
                    If you are looking for a scrolling format of my profile defined with minimal graphics all laid out in a single document ready to be fed into your Applicant Tracking System or a Resume Analyser powered by AI to best match my profile for your JD . . .
                    <br />
                    OR
                    <br />
                    Has ability to scan resumes in seconds . . .
                    <br /><br />
                    . . . then this is for you. Please find the descriptive document (available in .docx, .otd, .rtf, .pdf, .txt, .html, .epub via File &gt Download) accessible through the below link.
                    <br /><br />
                    hyperlink :  <Aln lhref="/cv" text="mghr-cv" />

                </p>
            } />

            <ContentHeadline title="Skills & Experties" />

            <Content c={
                <p>I was in my 11th Grade (2013) when I was first introduced to a High-Level Language. I was using
                    a September 5, 2006, stable release of software used to <i>Evaluate</i> my list of commands and <i>Translate</i> it to
                    a sequence of binaries with a press of a simple keyboard shortcut "Ctrl+F9" or "Alt+R+Enter". My learning progressed by
                    always being eager to include two libraries for every program, adding a prefix that means NOTHING to the program starter
                    function & a semi-colon for every line.
                    My frequent VIPs - Pointers use to motivate & point me to the place where I must add a STAR to
                    get other's data from memory, which eventually saved me from going crazy seeing my data lost in the sequence of
                    '0x0123456789ABCDEF' or similar.
                    <br /><br />
                    Funny, Though, I learnt the Extended version of the language first and later struggled with the Basic Letter.
                    Over the course, I felt like a HERO after I magically decremented the errors from 32 to ZERO ("Success: Press any Key") in a
                    Tower-of-Hanoi-with-Pointers-Recursion-&-Classes.CPP file.
                    <br /><br />
                    Well!!!, I was learning <u>C++</u> (first) and <u>C</u> (second) with turbo C Compiler (developed for windows) with pure
                    dedication from my Teacher <b>'Sir Keshav Murthi'</b>.
                    Who gave me (and others) a solid foundation for commanding computers in human-understandable language.
                    <br /><br />
                    These lessons on how to stand eventually made me run to catch for modern advanced <u>Pythons</u>, <u>Cran</u> & <u>Dockers</u>.
                    My Store rooms named <u>SQL</u> & <u>MongoDB</u> helps me securely store my catch.
                    <u>Git</u>; my logbook helps commit<strike>ted</strike> to my tasks and keep me well Organised.
                    Currently, I am utilising all my skills to explore the Thick Ever Growing Dark Forests
                    <u>'AI'</u>, <u>'Data Science'</u>, <u>'Statistical Techniques'</u>, and <u>'Blockchain'</u>.
                    <br /><br />
                    My wandering places, primarily above, will take some time to visit areas like <u>'Advance Software Engineering'</u> & <u>'Big Data'</u>
                    to find paths to connect Myself and other cross-connections between Forests. (...which has made me an architect of this site)


                </p>
            } />

            <ContentHeadline title="Internships" />
            <Topic title="Android Developer - Accord Global Technological Solutions" />
            <SubTopic title="(Feb-2019 to Mar-2019)" />

            <Content c={
                <p>
                    'The Senate' (My College, My Friends & My Family) of my assembly in the Year 2019 encouraged me to get a Corporate Experience.
                    The Internship was the best bet.
                    An Opportunity at Accord Global Technological Solutions was benefitting, (a). My College to grant me credit for my subject & to showcase
                    their training expertise to the future market, (b). My employer to Exploit the workforce without investment (funds & resources).
                    (c). Me with no Stipend & Increased Expenditure.
                    With the addition of another member - My Employer to 'The Senate', The 4M's felt like everything was Perfect from their Desk.
                    <br /><br />
                    While My Desk was perfectly imperfect, with an Asus laptop running Windows 10 (cracked) on a Core 2 duo intel processor,
                    2 Gigs of memory, hard spinning 350GB hard disk drive, a leaky Battery & a Android Studio with Emulator setup,
                    waiting like a Hungry Beast to suckup every second of mine.
                    <br /><br />
                    On the other side, I learnt with all my Good Will & Enthusiasm... 1. Android Development, 2. Software Development processes 3. Development Protocols,
                    4. Documenting, 5. Communicating with the team, 6. Handling Pressure & Deployment Issues, 7. Effective Money Management (because of (c). above)
                    8. Professional Ethics, 9. Lots & Lots of Patience from Mr. Asus. Finally, I finished my Internship in 2 months which was intended to be 3 months,
                    with an offer to join the Company. Most importantly, I saved 1/3rd of my total investment by completing my work in 2 months.
                    <br /><br />
                    My take-away for myself and others; 2. Make sure to have a good computer or modify the project to fit your computer. 1. Ask for a Stipend!!!.
                    0. Never Ever reject the Offer Letter (like this or in similar situations).
                    (2022 - when rewriting this byte of memory, Interns are still offered without Stipends, Please Offer them Competitively!!! if you are not a 'HR' like me)
                </p>
            } />

        </div>
    );
}

export default Three;