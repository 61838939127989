import { Aln, Span, PageHeadline, ContentHeadline, Content, FigureCaption } from "../mods/mods";

function One() {
    return (
        <div>

            <PageHeadline title="Welcome..." />
            <ContentHeadline title="mghr-pedia..." />

            <FigureCaption figureSource="assets/Wel-Img-1.png" figureCaption={<span>The Author !!! <br /> (2019 - Out of Fantasy)</span>} />

            <div class="bio-body">
                <div class="bio-div">
                    <span class="bio-bullet"> ◆ </span>
                    <span class="bio-l">Mohith Gowda Heggur Ramesh </span>
                    <span class="bio-r">(is My Full Name. You can call me) </span>
                    <span class="bio-l">Mohith.</span>
                </div>
                <div class="bio-div">
                    <span class="bio-bullet"> ◆ </span>
                    <span class="bio-l">hr.mohith.gowda </span>
                    <span class="bio-r">(atgmail.com is my personal@email.address)</span>
                </div>
            </div>

            <Content c={
                <div>
                    <p>
                        I can relate (myself) to the characteristics of characters from a game from 6 AC. The traits of heroes in the
                        game of Chess (ಚದುರಂಗ).
                        <ol>
                            <li>
                                I am driven by two things 1. Aim & 2. Focus (I am a failure if any one of them is missing), and do
                                unitasking; Like the pawn
                                aims to rank up to Get Promotion or Underpromotion.
                            </li>
                            <li>
                                'C' for Castle; 'C' for Confident & Strong, I am.
                            </li>
                            <li>
                                Unique, Complex & Highly Detail Oriented, my views and work are, similar to the Knight.
                            </li>
                            <li>
                                I am Spiritual and bound by Rules & Promises (always) like a Bishop is tied to a White or Black array of
                                Tiles.
                            </li>
                            <li>
                                The Queen - Any No. of Steps, Any Direction, Any Tile; & I am - Extremely Hard Working, Overthinker and
                                Ambivert.
                            </li>
                            <li>
                                Peace is the King of my life, for which I fight to protect.
                            </li>
                        </ol>
                        Well, do you like to play chess!!!
                    </p>

                    <ContentHeadline title="My Stories..." />
                    <Content c={
                        <p>
                            I feel and have witnessed several times that, Things Happen! (beyond our control), and they are always Good.
                            Patience & Courage to accept and see the Good out of uneven events is what is Essential. Its like Isolating
                            Gold from Dirt. Sometimes, even
                            small events teach Great things. They add more value to our lives.
                            <br /><br />
                            I have come across many such uneven times. When I look back, I say, "Wow...How well Timed & Planned it was
                            for me."
                            From the actions, place to people in the plot. Out of many such events, I list a few significant ones that
                            have
                            transformed my life. I lay them out &nbsp;
                            <Aln lhref="/education" text="here" /> and &nbsp;
                            <Aln lhref="/workandpassion" text="here" /> as well.

                        </p>
                    } />

                    <ContentHeadline title="My Pitch..." />
                    <Content c={
                        <p>
                            I mostly spend my time researching Artificial Intelligence, Data Science, Statistics and Blockchain related
                            topics.&nbsp; <Aln lhref="/workandpassion" text="Here" /> is a list of other stuff I have
                            experience with. If you are looking forward to collaborating or seak for help
                            on the aforementioned topics, reach out to me, and I would be happy to Help.
                            <br /><br />
                            I love to write, Particularly research papers. My journey has just begun & My Professor, <Aln
                                class="content-p" lhref="http://gabbay.org.uk/" text="Dr Jamie" />, is my Inspiration.
                            Here's my <Aln lhref="/paperworks" text="Portfolio" />. I would be delighted
                            in joining your research group and contributing to bringing novelty
                            and innovation to the project in areas of my interest (as mentioned before).
                            <br /><br />
                            Please find my details <Aln lhref="/workandpassion" text="here" />, if you
                            are looking forward to hiring me for a designated role.
                            <br /><br />
                            I only prefer Email. So, please email if you wish to Collaborate, Seak Help or Looking to Hire. (No Spam
                            Please...)

                        </p>}
                    />


                    <ContentHeadline title="Ack... & Thanks-To..." />
                    <Content c={
                        <p>
                            <ol>
                                <li><b>Declaration:</b> This is a Personal Website built out of Passion & doesn't encompass Commercial
                                    Factors.</li>
                                <li>...the authors of
                                    &nbsp;
                                    <Aln lhref="https://docs.microsoft.com/en-gb/typography/font-list/cambria"
                                        text="Cambria" />,
                                    &nbsp;
                                    <Aln lhref="https://www.ajtypography.com/shop/indulge-script"
                                        text="IndulgeScript" />,
                                    &nbsp;
                                    <Aln lhref="https://freefontsfamily.com/noteworthy-font-free-download/"
                                        text="Noteworthy" />, &
                                    &nbsp;
                                    <Aln lhref="https://www.fontspace.com/youth-touch-font-f30771"
                                        text="YouthTouch" />.
                                </li>

                                <li>...You, if you had cited me as <b>'Mohith Gowda HR'(https://mghr.org)</b> for using any of my works.(if
                                    any)</li>
                                <li>...You Of Course for Tolerating me!!!</li>
                            </ol>

                        </p>
                    } />

                </div>
            } />
        </div>
    );
}

export default One;