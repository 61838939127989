

function Footer(){
    return(
        <div class="footer">
            © Mohith Gowda HR, 2021 - {new Date().getFullYear()} | 
            <i>Last Updated on {2021}</i>
        </div> 
    )
}

export default Footer;