import Footer from './footer'
import Menubar from './menubar';
import One from './sections/one';
import Two from './sections/two';
import Three from './sections/three';
import Four from './sections/four';
import Five from './sections/five';
import '../styles/index.css'

function Home({ section }) {

    return (
        <div class="body">
            <hr class="margin-line" id="u" />
            <div class="head-div">
                <div class="typewriter">
                    <h1>@mghr...</h1>
                </div>
            </div>
            <hr class="margin-line" id="d" />

            <div class="body-div">
                <Menubar selection={section} />

                <div class="content"  >
                    {section === "one" && <One />}
                    {section === "two" && <Two />}
                    {section === "three" && <Three />}
                    {section === "four" && <Four />}
                    {section === "five" && <Five />}
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Home;