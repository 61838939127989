import { PageHeadline, ContentHeadline, Topic } from "../mods/mods";
import '../../styles/four.css'

function Four() {


    return (
        <div>
            <PageHeadline title="Paper-Works" />
            <ContentHeadline title="Thesis" />

            {/* <!-- latest (dec)--> */}
            <PW_ITEM
                type="ARTICLE"
                id="T1"
                title="Mempool Analysis to Identify Fraudulent Transactions in the Blockchain Network"
                keywords="Mempool, Bitcoin, Fraudulent Transactions, Financial Systems, Crypto Currencies, Attacks, Money Laundering, Unsupervised Learning, Kmeans++, Isolation Forest, Self-Organising Maps, Fraud Prevention, Blockchain"
                doi="NA"
                pdf="Download"
                citations="NA"
            />
            {/* <!-- next --> */}
            <ContentHeadline title="Articles & Conferences" />
            {/* <!-- latest (dec)--> */}
            <PW_ITEM
                type="ARTICLE"
                id="A2"
                title="Real-Time Agriculture Yield Monitor System (AYMS) Using Deep Feedforward (DFF) Neural Network"
                keywords="Machine Learning, Artificial Intelligence (AI), Smart Farming, Deep FeedForward Neural Network(DFF), Distance Monitoring, Internet of Things (IoT)"
                doi="http://dx.doi.org/10.2139/ssrn.3734201"
                pdf="https://deliverypdf.ssrn.com/delivery.php?ID=362104126089103066121096103017079124050050034063008064066124008031026097112119071089098124053005104010109117065100114086119074104015058046044074004001126076031011064090081060009021100116025088119082093019114071073017073015105125126097014126025095119091&EXT=pdf&INDEX=TRUE"
                citations="@article&#123;
                author = &#123;Manoj Athreya C S and Mohith Gowda H R and Amogh Babu K A and Ravishankar R&#125;,
                doi = &#123;10.2139/SSRN.3734201&#125;,
                journal = &#123;SSRN Electronic Journal&#125;,
                month = &#123;11&#125;,
                publisher = &#123;Elsevier BV&#125;,
                title = &#123;Real-Time Agriculture Yield Monitor System (AYMS) Using Deep Feedforward (DFF) Neural Network&#125;,
                url = &#123;https://papers.ssrn.com/abstract=3734201&#125;,
                year = &#123;2020&#125;,
                &#125;"
            />
            {/* <!-- next --> */}
            <PW_ITEM
                type="ARTICLE"
                id="A1"
                title="Development of Anti-Phishing Browser based on Random Forest and Rule of Extraction Framework"
                keywords="Anti-Phishing Browser, Phishing Attack, Machine Learning, Intelligent Browser Engine, Rule of Extraction Framework, AI Browser Architecture"
                doi="https://doi.org/10.1186/s42400-020-00059-1"
                pdf="https://cybersecurity.springeropen.com/track/pdf/10.1186/s42400-020-00059-1.pdf"
                citations="@article&#123;
                Hr2020,
                author = &#123;Mohith Gowda Hr and Adithya Mv and S. Gunesh Prasad and S. Vinay&#125;,
                doi = &#123;10.1186/S42400-020-00059-1/TABLES/4&#125;,
                issn = &#123;25233246&#125;,
                issue = &#123;1&#125;,
                journal = &#123;Cybersecurity&#125;,
                month = &#123;12&#125;,
                pages = &#123;1-14&#125;,
                publisher = &#123;Springer Science and Business Media B.V.&#125;,
                title = &#123;Development of anti-phishing browser based on random forest and rule of extraction framework&#125;,
                volume = &#123;3&#125;,
                url = &#123;https://cybersecurity.springeropen.com/articles/10.1186/s42400-020-00059-1&#125;,
                year = &#123;2020&#125;
                &#125;"
            />

            <ContentHeadline title="Blogs" />

            {/* <!-- latest (dec)--> */}
            <PW_ITEM
                type="BLOG"
                id="B2"
                title="Edge Computing : TPH Anomaly Detection using Multilayer Neural Network on ESP32"
                keywords="EDGE - C, Edge Computing, TPH Anomaly Detection, ESP32, ANN, AI & Machine Learning, Tensorflow, Tensorflow Lite"
                link="https://mmohithgowda.medium.com/edge-computing-tph-anomaly-detection-using-multilayer-neural-network-on-esp32-33d08fea00e9"
                citations="
                @web_page&#123;
                author = &#123;HR Mohith Gowda&#125;,
                title = &#123;Edge Computing : TPH Anomaly Detection using Multilayer Neural Network on ESP32 | by MohithGowdaHR | Medium&#125;,
                url = &#123;https://mmohithgowda.medium.com/edge-computing-tph-anomaly-detection-using-multilayer-neural-network-on-esp32-33d08fea00e9&#125;,
                year = &#123;2020&#125;
                &#125;"
            />
            <PW_ITEM
                type="BLOG"
                id="B1"
                title="An Introduction to Edge Computing On ESP32"
                keywords="EDGE - C, Edge Computing, ESP32, ANN, AI & Machine Learning, Tensorflow, Tensorflow Lite"
                link="https://www.hackster.io/edge-c/an-introduction-to-edge-computing-on-esp32-6f3834"
                citations="
                @web_page&#123;
                author = &#123;S Shiva Shankar and HR Mohith Gowda&#125;,
                title = &#123;An Introduction to Edge Computing On ESP32 - Hackster.io&#125;,
                url = &#123;https://www.hackster.io/edge-c/an-introduction-to-edge-computing-on-esp32-6f3834/&#125;,
                year = &#123;2020&#125;
                &#125;"
            />
            {/* <!-- next --> */}

        </div >
    );
}

export default Four;


const PW_ITEM = ({ type, id, title, keywords, doi, pdf, citations, link }) => {


    const viewCitations = (BC, CC) => {
        console.log(CC);
        if (document.getElementById(CC).style.display == "none" || document.getElementById(CC).style.display == "") {
            document.getElementById(CC).style.display = "flex";
            document.getElementById(BC).value = "hide";
            document.getElementById(BC).innerHTML = "hide";
        } else {
            document.getElementById(CC).style.display = "none";
            document.getElementById(BC).value = "show";
            document.getElementById(BC).innerHTML = "show";
        }

        var elements = document.getElementsByClassName('button-copy');
        var i;
        for (i = 0; i < elements.length; i++) {
            elements[i].value = "Click to Copy...";
            elements[i].innerHTML = "Click to Copy...";
        }
    }

    const copyCitations = (cit, citBtn) => {
        var copyText = document.getElementById(cit.toString());
        navigator.clipboard.writeText(copyText.innerHTML);
        var btn = document.getElementById(citBtn.toString());
        if (btn.value.toString() == "Click to Copy...") {
            btn.value = "Copied!!!";
            btn.innerHTML = "Copied!!!";
        }
        else if (btn.value.toString() == "Copied!!!") {
            btn.value = "Already Copied ! ! !";
            btn.innerHTML = "Already Copied ! ! !";
        }
    }


    return <div class="content-h4">
        <Topic title={title} />
        <table>
            <tr>
                <td>
                    <pre class="content-p">Keywords:</pre>
                </td>
                <td><p class="content-p">
                    {keywords}
                </p></td>
            </tr>
            {type === "ARTICLE" &&
                <tr>
                    <td>
                        <pre class="content-p">DOI:</pre>
                    </td>
                    <td>
                        <a class="content-p" href={doi} target="_blank">{doi}</a>
                    </td>
                </tr>
            }
            {type === "ARTICLE" &&
                <tr>
                    <td>
                        <p class="content-p">PDF:</p>
                    </td>
                    <td>
                        <a class="content-p" href={pdf} target="_blank">Download</a>
                    </td>
                </tr>
            }
            {type === "BLOG" &&
                <tr>
                    <td>
                        <pre class="content-p">Link:</pre>
                    </td>
                    <td>
                        <a class="content-p" href={link} target="_blank">{link}</a>
                    </td>
                </tr>
            }
            <tr>
                <td>
                    <p class="content-p">Citations:</p>
                </td>
                <td>
                    <button class="button-citations" id={"BC-" + id} type="button" onClick={() => viewCitations("BC-" + id, "CC-" + id)} value="show">show</button>
                </td>
            </tr>
        </table>
        <div class="contents-citations" id={"CC-" + id}>
            <button onClick={() => copyCitations("C-" + id, "BnC-" + id)} class="button-copy" id={"BnC-" + id} value="Click to Copy...">Click to Copy...</button>
            <pre class="citations" id={"C-" + id}>
                {citations}
            </pre>
        </div>

    </div>
};
