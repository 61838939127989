import { Aln, Span, PageHeadline, ContentHeadline, Content, FigureCaption, Topic } from "../mods/mods";

function Two() {
    return (
        <div>
            <PageHeadline title="Education" />
            <ContentHeadline title="Master of Science in Data Science" />

            <FigureCaption figureSource="assets/Edu-Img-1.png" figureCaption={<span>Edu-Img-1: 2021 Graduation Ceremony at Heriot-Watt University (08-Dec-2021).
                <br />(Dark Circles - I did'nt get enough sleep the day before my Graduation because of the guy st[a]nding beside the per[s]on taking this picture.
                - aDITHYA, sHREYAS ARUNESH)</span>} />


            <Content c={
                <p>

                    <Topic title="The Spark;" />

                    <p>
                        I finished my bachelor's. My Father insisted on taking up higher studies, and he wanted me to pursue my Master's in any US university.
                        I submitted my application to my prefered list, got rejected by some, and accepted by many. Out of all, I accepted the offer from UAB,
                        Birmingham, US, based on curriculum structure. I paid my fees (and later got refunded with another application) and received my I20.
                        At this moment, in late 2020, August, COVID19 indirectly shattered my dreams through US Presidents Volatile, Vivid, Vague press releases
                        on aspiring International Students (RSN: travel ban & later arrivals to be considered as Online Masters). Time ticking and press releases
                        ramping up as SEP approached, I decided to Change Flight and Flew to the UK.
                    </p>
                    <Topic title="God's Grace;" />
                    <p>
                        During my application process, I had considered the UK as my second option, and it was waiting to help me. Out of all offers from
                        universities in the UK, I chose and accepted my offer from Heriot-Watt University (based on Curriculum again) to pursue MSc in Data Science
                        just 15 days before the course commenced. I started my course online and later joined the campus in the middle of my first semester (Nov, 2020).
                        My Master's, a huge mountain to climb, seemed small besides getting my documents sorted for my intended journey.

                    </p>

                    <Topic title="Loan & Visa;" />

                    <p>
                        Both Brothers on intense competition to suck up all my joy joyously & are liable for my late arrival to the UK (with the other partner being
                        the US President ). <br /><br />
                        (A). The Loan... at least in 2020, Educational Loans were granted under two schemes, With Collateral and Without.
                        My total expenditure was expected to be around ₹27L, which was compulsory to be on paper for the Second Brother. Being short on funds (Personally),
                        My Father and I reached out for help - Mr Bank. He is a Polymorph, (Nationalised) Mr Ḃank and Mr Ḅank (Privatised). My father had a property (a land)
                        that was not accepted, as Mr Bank's protocols were not in line with property documents (and not the other way round). Option second was to go for
                        without collateral, Mr Ḅank immediately rules out, and Mr Ḃank wanted me to undergo the application process. Under without collateral, the amount is
                        capped to ₹7.5L (the rest, I must arrange). My biggest huddle was getting many, several and more duplicate copies of all my documents, travelling to
                        Mr Ḃank's home in different towns and Hearing "come back tomorrow" without any kindness. I went on selling my time with the application to Mr Ḃank
                        a,b,c & d. finally, I got my 7.5L, and the rest came from my father's savings and Gold Load.
                        <br /><br />
                        (B). Brother Visa Said, <i>"Great !!! you have got your financial documents (from my Brother) sorted & others as well, please proceed to the application." </i>
                        It was my badest time. During my application process, there was a server error, and my health insurance was not processed correctly. Guess what, debt
                        transaction on my account and incomplete application as per the VFS Global employees. I had to request a refund which would take about a month, and file another
                        application after rearranging my funds. "Days were gone in a blink." So was my syllabus...
                        <br /><br />

                        <b>Me to Myself: </b> <i>Mohith, what's happening. </i>
                        <br />
                        <b> Mohith: </b> <i>f uuu...!!! it's just the process, enjoy!!! </i>

                    </p>

                    <Topic title="The Climax;" />
                    <p>
                        I received an SMS from VFS global saying my passport with the VISA update will be delivered tomorrow. my father and I, eager
                        to collect ASAP, contacted the nearest depot. It was not there. Tension Circulated!!!. Tracing its current location from the depot members, The lost
                        Passport was travelling to the wrong destination.
                        <br /><br />

                        <b>My Father: </b><i> Beep.....beep.....beep.....bee...eep...beep... </i>
                        &nbsp;
                        <i> (don't worry, my reader, I have censored it for you) </i>
                        <br />
                        <b>Me: </b><i> I have given the correct address, here's the proof, take...
                        </i>
                        <br /><br />
                        I kept a copy of my application before him. The address on the application was Perfect - Good. The address on the parcel traced was also Perfect, except for
                        the Pincode. It was an apparent mistake of the VFS employee, & I was saved, and it wasn't my fault. The passport was withheld by another depot around
                        500KM away from my place. The question was, who is gonna travel to collect it tomorrow? To make the situation worst, my Fathers sister had to undergo
                        surgery tomorrow, and his presence was crucial. So, finally, at midnight, we decided to exchange our responsibilities. My father travelled to get my
                        Passport, and I was doing his duty of looking after his sister (& my parental aunt, of course!!!).
                        <br /><br />
                        The Victorious Ending... My father got my passport, and my parental aunt's surgery
                        was successful & she was safe. The Dramatic, traumatic show concluded happily... I travelled to the UK with Memories and Ambitions with British Airways.
                        I had Booked one seat, But they were highly gracious for my Bravery and had kept several seats clear to make me feel like Business Class in an
                        Economic seat with excellent safety and sanitisation (I didn't catch COVID19, nor did it catch me).

                    </p>
                    <Topic title="The Gurukul;" />
                    <p>
                        After arriving late with the only Ambition to learn and earn Knowledge & a Master's Degree, I finally had the freedom to spend my
                        precious TIME with all my goodwill to only my Studies. I researched, experimented, tested and improvised every single day on, What to learn?
                        How to learn? How to do things better? Resulting in exponential growth in my productivity. I enjoyed!!!.
                        <br /><br />
                        At the beginning of this process, I was very grateful to meet my Professor and Supervisor for my Project,&nbsp;
                        <b><Aln class="content-p" lhref="http://gabbay.org.uk/" text="Dr Murdoch James Gabbay" /></b>
                        &nbsp;(Dr Jamie).
                        I was supposed to choose a Project for my Thesis. I went to the Project Portal and filtered to find a few of my INTEREST, <b>[FORTUNATELY]</b>, all of
                        them were allocated to others. Out of all the remaining, there was one for which I got latched on to - "The Blockchain  -  Anything to do with
                        Blockchain." This was a Generalised title and topic supervised by my Professor, Dr Jamie. Out of clear interest, I requested my professor to consider
                        me for this project & immediately, he rang me on MS Teams and finalised me for the project.
                        <br /><br />
                        Initially, out of Ignorance on Blockchain and Research, I had proposed a meaningless project straight out of fantasy (just like every student).
                        I was devastated on hearing his feedback on the proposal and for making him lose interest in the project.
                        On this day, I woke up to reality and accepted a topic he suggested to me on working on.
                        With less than 3 months for my first deliverable, I started to research & learn, starting from, What is a Blockchain and the Blockchain Transaction Mechanism
                        (the very basic), to propose my Thesis as
                        &nbsp;
                        <Aln lhref="/paperworks" text="Mempool Analysis to Identify Fraudulent Transactions in the Blockchain Network." />
                        &nbsp;
                        This was only possible because of
                        my Supervisor's Excellent mentoring. I have learnt many things from him. In a general context, I learnt the art of...
                        <ol>
                            <li>Research,</li>
                            <li>Blockchain & Data Science, </li>
                            <li>Academic Writing and </li>
                            <li>Demonstrating complex things the simple way.</li>
                        </ol>

                        After submitting the first deliverable, I looked back & felt how Lucky and Blessed I was to study under him. I felt delighted because somehow, I
                        had managed to regain his trust in me in & around 3 months. The results were out!!! Over everything, the one thing I still wish to remember today is
                        his feedback (below in Edu-Img-2) not for the positive aspect, But for his "Satisfaction" with my work (It was more than everything, even my degree. a student
                        scoring 100/100 wouldn't be happy as I was)
                        <br /><br />

                        <FigureCaption figureSource="assets/Edu-Img-2.png" figureCaption={<span>Edu-Img-2: Feedback from my Supervisor - Dr Jamie for my Thesis first deliverable.</span>} />

                        He was (& is ) my "True Inspiration". I was Blessed to Study, Work and Learn invaluable lessons no matter what it is, Academic or Life Lessons from him.
                        He has Formulated and Influenced every little step of mine to perfection at Heriot-Watt and to the extent that it is still assisting me Drive Through.
                        This was why I mentioned I was so <b>[FORTUNATE]</b> to lose other projects of my interest to others in the first place.

                        <br /><br />

                        Through this, I graduated from Heriot-Watt University on 8th-Dec-2021, with imperishable memories & Life Lessons to face the world confidently.

                    </p>
                </p>
            } />

            <ContentHeadline title="Bachelors of Science in Computer Science and Engineering" />

            <Content c={
                <p>
                    <p>
                        My Four splended years narrowed down to key frames, listed in order.

                    </p>
                    <Topic title=" BE2015-16: " />
                    <p>
                        <ol>
                            <li>
                                Being Positively Oriented toward Computer Science and Computer Programming with the influential teachings of my Teacher
                                Sir Keshav Murthi in my Pre-university course (CS) (& high grades in the PUC final exam) motivated me to take up my Bachelor's
                                Degree at People's Education Society (PES) College of Engineering (affiliated by Visvesvaraya Technological University)
                                in Computer Science and Engineering.
                            </li><li>
                                A month after the Grand Orientation Day, I was Disoriented, studying all those courses irrelevant to Computer Science
                                (Physics, Mechanical, Electrical and others) of other departments, and it was just getting started.
                            </li><li>
                                I realised that learning bits of Non-CS subjects (the COCKTAIL)(only helped me progress towards my degree) was never beneficial.
                                I Believe, Every field to bring innovation demands in-depth knowledge and expertise. & I must Quote,
                                <i>"The long term effect of Overdosed COCKTAIL eventually is to FORGET everything without BENEFITING".</i>
                            </li><li>
                                Second Semester... & more of Cocktail !!! This time there were no CS subjects at all.
                            </li><li>
                                It was Frustrating, Foiled & Frighting !!!
                            </li><li>
                                But, following the distributaries of Computer Science (theoretically & experimentally), specifically advanced C/C++, was a Blissssss...
                                & I Enjoyed.
                            </li>
                        </ol>
                    </p>
                    <Topic title=" BE2016-17: " />

                    <p>
                        <ol>
                            <li>
                                Finally, CS-COHOL was served. Every sip was rewarding, resulting in a Dopamine Rush.
                            </li><li>
                                The Rush helped me make more errors. Eventually, I started populating with bugs to simple check what happens, rather than showing the
                                programme output to the Professor.
                            </li><li>
                                I was just learning n-ways to FAIL to find the best way to SUCCEED.
                            </li><li>
                                I was a Fandom for working on Data Structure with C++ in Kali Linux & 8086 Instruction Sets was Epic.
                            </li>
                        </ol>
                    </p>
                    <Topic title=" BE2017-18: " />
                    <p>
                        <ol>
                            <li>
                                By this time, I had mastered staying awake the whole night. Sleep @ 6 AM and Wake Up 30 min before my 9 AM class.
                            </li><li>
                                I had mastered starting my assignment the night before Submission to see my book pop out first.
                            </li><li>
                                I had mastered finishing the entire Syllabus in one night.
                            </li><li>
                                I had mastered executing programs well (all kinds).
                            </li><li>
                                I had mastered standing for a decision to drop a mini-project and start a new one overnight for the next day's Submission.<sup><b>†</b></sup>
                            </li><li>
                                Finally, I had mastered managing my busy day.
                            </li>
                            <sup><b>†</b></sup>I started a mini project on IoT, which dropped off due to a lack of resources. I started & completed a new one with the available resources to combine both IoT & ML. The outcome was extremely promising.

                        </ol>
                    </p>
                    <Topic title=" BE2018-19: " />
                    <p>
                        <ol>
                            <li>
                                The College, My Parents & My Friends were the driving force to take up my Internship.
                            </li><li>
                                Out of nowhere, I was struct with an Idea for my Project to design my own version of a Browser with enhanced security
                                powered by Machine Learning. The ML model deployed is used to detect Phishing websites and alert users while browsing
                                both inter... and intra... ...net.
                            </li><li>
                                The Hardest Part was finding a touchpoint between the C# Browser and the ML Python model.
                            </li><li>
                                & Higher Studies.
                            </li><li>
                                Graduating with lots of Love & Memories (Edu-Img-3) from my Loved ones.
                            </li>
                        </ol>
                    </p>
                </p>
            } />
            <FigureCaption id="Edu-Img-3" figureSource="assets/Edu-Img-3.png" figureCaption={<span>Edu-Img-3: The Three Musketeers.
                <br />(Mohith - Mahesh - Adithya)</span>} />

        </div>
    );
}

export default Two;